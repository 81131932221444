import React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import "../styles/layout.css"
import CookieConsent from "react-cookie-consent"

export default ({ children }) => {
  return (
    <>
      {/* Google Tag Manager (noscript) */}
      {typeof window !== "undefined" &&
        window.document &&
        window.document.createElement && (
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-PNQKGQJ"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>
          </noscript>
        )}
      {/* End Google Tag Manager (noscript) */}
      <Navbar />
      <main>{children}</main>
      <Footer />
      <CookieConsent
        style={{
          background: `#c1eff1`,
          color: `#2f394e`,
          fontSize: `14px`,
        }}
        location="bottom"
        buttonText="Got It"
        buttonStyle={{
          color: "white",
          background: "#33cdd1",
          fontSize: "13px",
          borderRadius: `4px`,
          padding: "8px 25px",
          marginRight: `100px`,
        }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        We use cookies on this website. By using this site you agree that we may
        store and access cookies on your device
      </CookieConsent>
    </>
  )
}
