import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"

const NotifiContainer = styled.div`
  background: #0d0c22;
  font-size: 16px;
  color: white;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1fr 25px;
  grid-gap: 15px;
  justify-items: center;
`

const Cross = styled.div`
  text-align: center;
  line-height: 25px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 13px;
`
const Highlight = styled.span`
  color: #ffc83d;
  font-weight: 600;
`

const UpperNotification = props => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    let today = moment()
    let startDate = moment("22-11-2022", "DD-MM-YYYY")
    let endDate = moment("31-12-2022", "DD-MM-YYYY")

    setShow(today.isBetween(startDate, endDate))
  }, [])

  const closeShow = () => {
    setShow(false)
  }

  return show ? (
    <NotifiContainer>
      <div>
        {" "}
        {/* <span>{props.spark}</span>  */}
        Grab 25% special discount before it runs out. Apply the coupon:{" "}
        <Highlight>MONDAY25</Highlight> {/* <span>{props.spark}</span> */}
      </div>
      <Cross onClick={() => closeShow()}> {props.cancel} </Cross>
    </NotifiContainer>
  ) : (
    <></>
  )
}

export default UpperNotification
