import React, { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import UpperNotification from "./UpperNotification"

const StyledLink = styled(Link)`
  text-decoration: none;
`
const StyledNav = styled.nav`
  position: sticky;
  position: -webkit-sticky;
  top: -2px;
  z-index: 2;
  width: 100%;
  background-color: white;
  box-shadow: ${props => props.styled && `5px 5px 20px 1px rgba(0, 0, 0, .05)`};
`
const NavbarGrid = styled.div`
  display: grid;
  grid-template-columns: 185px auto;
  padding: 15px 8%;
  margin-top: ${props => !props.styled && `30px`};
  justify-content: space-between;
  align-content: baseline;
  font-family: "Montserrat", sans-serif;
  transition: margin-top 0.1s linear;
  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    justify-content: center;
    padding: 10px 0;
    margin-top: 0;
  }
`
const NavInnerGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: end;
  align-content: center;
  grid-column-gap: 2vw;
  @media (max-width: 1150px) {
    grid-template-columns: auto;
    grid-row-gap: 10px;
    justify-content: center;
  }
`
const LogoMob = styled.div`
  /* position: fixed;
  top: 10px;
  left: 15px; */
  /* margin-top: 5px; */
`
const HamIcon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 2px;
  background-color: white;
  border: none;
  &:focus {
    outline: none;
  }
`
const HamStick = styled.div`
  width: 23px;
  height: 2.5px;
  background-color: #2f394e;
  margin: 2px;
  border-radius: 3px;
`

const HamStickActive = styled.div`
  margin-top: 10px;
  width: 25px;
  height: 2.5px;
  background-color: #33cdd1;
  transform: rotate(45deg);
  border-radius: 3px;
`
const HamStickActiveReverse = styled.div`
  width: 25px;
  height: 2.5px;
  background-color: #33cdd1;
  transform: rotate(-45deg) translate(1.5px, -2px);
  border-radius: 3px;
`
const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: rgba(22, 31, 50, 0.8);
  font-size: 16px;
  text-decoration: none;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`
const NavA = styled.a`
  display: flex;
  align-items: center;
  color: rgba(22, 31, 50, 0.8);
  font-size: 16px;
  text-decoration: none;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`
const HoverRound = styled.div`
  width: 12px;
  height: 2px;
  border-radius: 20%;
  margin-top: 5px;
  background-color: white;
  border: none;
`
const NavItemsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover ${NavLink} {
    color: #33cdd1;
  }
  &:hover ${NavA} {
    color: #33cdd1;
  }
  &:hover ${HoverRound} {
    background-color: #33cdd1;
  }
`
const Login = styled.div`
  padding: 8px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33cdd1;
  border: 1px solid #33cdd1;
  white-space: nowrap;
  border-radius: 6px;
  &:hover {
    background: #26bdc1;
    border: 1px solid #26bdc1;
  }
  &:focus {
    outline: none;
  }
`
const JoinFbButton = styled.div`
  padding: 8px 25px;
  white-space: nowrap;
  color: #fff;
  background: #3d5b99;
  border: 1px solid #3d5b99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  &:hover {
    background: #335190;
    border: 1px solid #335190;
  }
`

const MobileNavContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
`

const Navbar = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0)
  const [isHamOpen, setIsHamOpen] = useState(false)
  const [styled, setStyled] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", resize)
    setWindowWidth(window.innerWidth)
    window.addEventListener("scroll", scrollTop)
  }, [])
  const resize = () => {
    setWindowWidth(window.innerWidth)
  }
  const toggleHam = () => {
    setIsHamOpen(!isHamOpen)
  }
  const scrollTop = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      setStyled(true)
    } else {
      setStyled(false)
    }
  }
  return (
    <StyledNav styled={styled}>
      {/* {windowWidth > 1150 && ( */}
      <UpperNotification
        cancel={
          <GatsbyImage
            image={data.cancel.childImageSharp.gatsbyImageData}
            style={{ overflow: "visible" }}
          />
        }
        spark={
          <GatsbyImage
            image={data.spark.childImageSharp.gatsbyImageData}
            style={{ overflow: "visible" }}
          />
        }
      />
      {/* )} */}
      <NavbarGrid styled={styled}>
        {windowWidth > 1150 && (
          <>
            <div>
              <StyledLink to="/">
                <GatsbyImage
                  image={data.logoWide.childImageSharp.gatsbyImageData}
                  loading="eager"
                  style={{ overflow: "visible" }}
                />
              </StyledLink>
            </div>
          </>
        )}

        {windowWidth <= 1150 && (
          <MobileNavContainer>
            <LogoMob onClick={() => setIsHamOpen(false)}>
              <StyledLink to="/">
                <GatsbyImage
                  image={data.logoMob.childImageSharp.gatsbyImageData}
                  durationFadeIn={50}
                  loading="eager"
                  style={{ overflow: "visible" }}
                />
              </StyledLink>
            </LogoMob>
            {isHamOpen ? (
              <HamIcon onClick={() => toggleHam()}>
                <HamStickActive></HamStickActive>
                <HamStickActiveReverse></HamStickActiveReverse>
              </HamIcon>
            ) : (
              <HamIcon onClick={() => toggleHam()}>
                <HamStick></HamStick>
                <HamStick></HamStick>
                <HamStick></HamStick>
              </HamIcon>
            )}
          </MobileNavContainer>
        )}

        {(windowWidth > 1150 || isHamOpen === true) && (
          <NavInnerGrid>
            <NavItemsBox>
              <NavLink
                to="/integrations"
                onClick={() => setIsHamOpen(false)}
                style={{ paddingTop: `9px` }}
              >
                Integrations
              </NavLink>
              <HoverRound />
            </NavItemsBox>

            <NavItemsBox>
              <NavLink
                to="/pricing"
                onClick={() => setIsHamOpen(false)}
                style={{ paddingTop: `9px` }}
              >
                Pricing
              </NavLink>
              <HoverRound />
            </NavItemsBox>

            <NavItemsBox>
              <NavA
                target="_blank"
                rel="noopener noreferrer"
                href="https://groupboss.io/blog/"
                onClick={() => setIsHamOpen(false)}
                style={{ paddingTop: `9px` }}
              >
                Blog
              </NavA>
              <HoverRound />
            </NavItemsBox>

            <NavItemsBox>
              <NavA
                target="_blank"
                rel="noopener noreferrer"
                href="https://groupboss.io/help/"
                onClick={() => setIsHamOpen(false)}
                style={{ paddingTop: `9px` }}
              >
                Docs
              </NavA>
              <HoverRound />
            </NavItemsBox>

            <NavItemsBox>
              <NavA
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.groupboss.io/login"
                onClick={() => setIsHamOpen(false)}
              >
                <Login>Sign In</Login>
              </NavA>
            </NavItemsBox>
            <NavItemsBox>
              <NavA
                target="_blank"
                rel="noopener noreferrer"
                href="https://affiliates.groupboss.io/login"
                onClick={() => setIsHamOpen(false)}
              >
                <JoinFbButton>Join Our Affiliate Program</JoinFbButton>
              </NavA>
            </NavItemsBox>
          </NavInnerGrid>
        )}
      </NavbarGrid>
    </StyledNav>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        logoMob: file(relativePath: { eq: "groupboss-logo-half.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 40
              height: 40
              placeholder: NONE
              layout: FIXED
            )
          }
        }
        logoWide: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 180
              height: 38
              quality: 100
              placeholder: NONE
              layout: FIXED
            )
          }
        }
        cancel: file(relativePath: { eq: "cancel.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 25
              quality: 100
              placeholder: NONE
              layout: FIXED
            )
          }
        }
        spark: file(relativePath: { eq: "spark.png" }) {
          childImageSharp {
            gatsbyImageData(
              height: 15
              quality: 100
              placeholder: NONE
              layout: FIXED
            )
          }
        }
      }
    `}
    render={data => <Navbar data={data} {...props} />}
  />
)
